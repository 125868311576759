<template>
  <div class="vcard__new">
    <template v-if="!loading">
      <EmployeeInfo
        @employeeInfo="setEmployeeInfo"
        :infoToUpdate="getEmployee"
        v-if="!loading"
      />
      <EmployeePhoto
        @employeeImage="setEmployeeImage"
        :employeeImage="getImage"
      />
      <button class="vcard__new__button" @click="createVcard">
        {{ id ? "Actualizar vCard" : "Crear vCard" }}
      </button>
    </template>
    <p v-else>Cargando...</p>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "createVcard",
  components: {
    EmployeeInfo: () => import("./components/employeeInfo.vue"),
    EmployeePhoto: () => import("./components/employeePhoto.vue"),
  },

  props: {
    id: {
      type: String,
    },
  },

  data() {
    return {
      employee: {
        image:
          "https://firebasestorage.googleapis.com/v0/b/di-medical-del-sur.appspot.com/o/static%2FuserIcon.png?alt=media&token=37e7160e-1ae7-41d3-b8d9-bad1e7662568",
      },
      loading: false,
    };
  },

  async mounted() {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    if (this.$props.id) {
      this.loading = true;
      try {
        const vCard = await this.getCard(this.$props.id);
        if (!vCard) {
          this.$router.push({ name: "Dashboard" });
          this.$toast.error("No se encontro la Vcard");
        }
        this.employee = vCard;
        this.employee.cardId = this.id;
        this.loading = false;
      } catch (error) {
        console.log(error)
        this.$router.push({ name: "Dashboard" });
        this.$toast.error("Ocurrio un error");
      }
    }
  },

  methods: {
    setEmployeeInfo(info) {
      this.employee = { ...info };
    },
    setEmployeeImage(image) {
      this.employee.image = image;
    },
    async createVcard() {
      const {
        name,
        job,
        phone,
        mail,
        last,
        sucursal,
        description1,
        description2,
        image,
      } = this.employee;
      if (
        !name ||
        !job ||
        !phone ||
        !mail ||
        !sucursal ||
        !description1 ||
        !description2 ||
        !last ||
        !image
      ) {
        this.$toast.error("Debes llenar todos los campos");
        return;
      }

      try {
        if (image instanceof File) {
          const urlImage = await this.uploadCardImage({
            cardId: name,
            file: image,
          });
          this.employee.image = urlImage;
        }

        if (this.$props.id) {
            console.log(this.employee)
            await this.updateCard(this.employee);
            this.$toast.success("Se actualizo correctamente la Vcard");
        } else {
          await this.createCard(this.employee);
          this.$toast.success("Se creo correctamente la Vcard");
        }
      } catch (error) {
        console.log(error);
        this.$toast.error("Ocurrio un error");
      }
      this.$router.push({ name: "Dashboard" });
    },
    ...mapActions("vCard", ["createCard", "uploadCardImage", "getCard", "updateCard"]),
  },

  computed: {
    getEmployee() {
      return this.employee;
    },
    getImage() {
      return this.employee.image;
    },
  },
};
</script>

<style>
</style>
